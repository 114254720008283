import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { adminLogin } from "../../../services/authService"; // Adjust the import path accordingly
import "../../css/login.css";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await adminLogin(email, password); // Use the adminLogin function
      navigate("/dashboard"); // Redirect to dashboard on successful login
    } catch (err) {
      setError(err.message); // Set error message if login fails
    }
  };

  return (
    <div className="bodLogin">
      <h1>Sign In (Administrator)</h1>
      <p>Sign in as administrator and start posting content</p>
      <div className="logContain">
        <form className="formLogin" onSubmit={handleSubmit}>
          {" "}
          {/* Use handleSubmit on form submission */}
          <div className="input">
            <label>Login ID *</label>
            <input
              type="text"
              required
              placeholder="Admin Email" // Changed placeholder to reflect email login
              className="text"
              value={email} // Bind email state
              onChange={(e) => setEmail(e.target.value)} // Update email state
            />
          </div>
          <div className="input">
            <label>Password *</label>
            <input
              required
              type="password"
              placeholder="Password"
              className="text"
              value={password} // Bind password state
              onChange={(e) => setPassword(e.target.value)} // Update password state
            />
          </div>
          <Link to={"mailto:excellence@thetrybeco.org"}>Forgot Password</Link>
          <button type="submit">Sign In</button>
          {error && <p style={{ color: "red" }}>{error}</p>}{" "}
          {/* Display error message */}
        </form>
        <div className="fotter2">
                      <Link className="fLink" to="/">
                        HOME
                      </Link>
          <Link className="fLink" to="/about">
            ABOUT
          </Link>
          <Link className="fLink" to="/publications">
            Publications
          </Link>
          <Link className="fLink" to="/contact">
            Contact
          </Link>
          <Link className="fLink" to="/store">
            Store
          </Link>
          <Link className="fLink" to="/privacy">
            Privacy
          </Link>
          <Link className="fLink" to="/signIn">
            Administrator
          </Link>
          <p>Copyright 2024</p>
          <p>
            Made With Love By{" "}
            <Link className="special" href="#">
              Excellence
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
