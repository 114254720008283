import React from "react";
import { Link } from "react-router-dom";

function Store() {
  return (
    <div className="logContain">
      <h1>ALAAROKUN STORE</h1>
      <h1>Coming Soon...</h1>
      <div className="fotter2">
                    <Link className="fLink" to="/">
                      HOME
                    </Link>
        <Link className="fLink" to="/about">
          ABOUT
        </Link>
        <Link className="fLink" to="/publications">
          Publications
        </Link>
        <Link className="fLink" to="/contact">
          Contact
        </Link>
        <Link className="fLink" to="/store">
          Store
        </Link>
        <Link className="fLink" to="/privacy">
          Privacy
        </Link>
        <Link className="fLink" to="/signIn">
          Administrator
        </Link>
        <p>Copyright 2024</p>
        <p>
          Made With Love By{" "}
          <Link className="special" to="#">
            Excellence
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Store;
