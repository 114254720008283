import React from "react";
import { Link } from "react-router-dom";
import "../css/about.css";

function About() {
  return (
    <div className="logContain">
      <div className="bodAbout">
        <h1 className="AboutH1">About Alaarokun</h1>
        <div className="aboutUsTextContain">
          <h3>The Legacy of Alaarokun</h3>
          <p>
            ALAAROKUN was the son of OLOWA in Ife. He embarked on a journey over
            400 years ago in search of prosperity. He settled in Uwoja, Afa
            kingdom, where he became the First Rawa of Afa Kingdom in history.
            His descendants, now scattered globally, constitute one of the most
            vibrant, formidable and successful personalities in Afa and Okeagbe.
            According to traditional records, Alaarokun’s illustrious reign as
            Rawa of Afa Kingdom spanned approximately 60 years. This rich
            heritage continues to inspire generations, fostering a strong sense
            of identity and unity amongst Alaarokun descendants.
          </p>
        </div>
        <div className="aboutUsTextContain">
          <h3>The Lineage of Alaarokun</h3>
          <p>
            Alaarokun had two children: OLETE (Male) and AYEGBO (Female).
            Unfortunately, Ayegbo passed away at a young age without any
            offspring. Olete, however, outlived his father and became the sole
            heir. Following the patrilineal system of succession, Olete ascended
            his father’s stool as the Second Rawa in the history of Afa Kingdom.
            His remarkable reign spanned approximately 100 years. Oral tradition
            records that he lived an extraordinary life, passing away at around
            130 years of age.
          </p>{" "}
          <p>
            Despite facing the challenges of the “Abiku” syndrome, Olete was
            blessed with a son named ERUAWO. Eruawo not only survived but
            thrived, succeeding his father as the Third Rawa in the history of
            Afa Kingdom. Eruawo’s reign was marked by greatness, prominence,
            power, and wealth. He married many wives and had many children
            amongst who are the following:
            <ol className="Order">
              <li>
                <b>AGABA</b> - The first child of Eruawo who unfortunately
                predeceased Eruawo and died childless;
              </li>
              <li>
                <b>SUNMONU</b> - After the death of his father, Sunmonu
                inherited Adesoro and begat ALIU, ALADE, and OLOGUNRO. He was
                also the father of IKUDOLANU, who begat OLA – the monther of
                late Pa Z.A. Alabi. He also begat OGUNLEYE;
              </li>
              <li>
                <b>OSALA</b> - He succeeded Eruawo as the Fourth Rawa in the
                history of Afa Kingdom. He begat 3 children – ONI ALAFUN, ELU
                and OGUNMOLARIN;
              </li>
              <li>
                <b>ADETILOYE</b> - Who begat ALE, OGUNDANA and OLORUNTOBA;
              </li>
              <li>
                <b>OLUBIDO</b> - Who died childless;
              </li>
              <li>
                <b>ATURU</b> - father of IGE who begat ALASEGE (YEMISI); Aturu
                was also the father of OGUNBI (mother of the late lawyer S.A
                OGEDENGBE);
              </li>
              <li>
                <b>AROGUNDADE</b> - Who died childless;
              </li>
              <li>
                <b>ALAGOOKUN</b> - Who died childless;
              </li>
              <li>
                <b>ABAYOMI</b> - The father of AINA, WILLIAM OLADOYINBO, JOSEPH
                OJO, JAMES DADA, AJAYI (WELLINGTON) EMMANUEL DADA, AKINTOLA,
                SIDELOLA, ONI, OMOLEYE, YINUSA and EKUNJILADE. He succeeded
                Osala as the Fifth Rawa in the history of Afa Kingdom;
              </li>
              <li>
                <b>OSADAHUN</b> - Who later settled in Ise-Akoko and begat
                ISINMI and Others;
              </li>
              <li>
                <b>ELEYINMI</b> - He begat AMUBIEYA;
              </li>
              <li>
                <b>SANNI</b> - He begat AREWA;
              </li>
              <li>
                <b>ATEGBE</b> - Who died childless;
              </li>
              <li>
                <b>ADU</b> - Who died childless;
              </li>
              <li>
                <b>ALAJIKI</b> - The first child of Eruawo who unfortunately
                predeceased Eruawo and died childless;
              </li>
              <li>
                <b>IYALODE</b> - She was powerful but died childless. She took
                care of Alaarokun children during the period of interregnum in
                Afa Kingdom;
              </li>
              <li>
                <b>SHEFI</b> - He begat FAKILE;
              </li>
              <li>
                <b>OMOBOLA</b> - Who died childless;
              </li>
              <li>
                <b>FAYOMI</b> - Whose descendants now reside in Aiyedun-Ekiti;
              </li>
              <li>
                <b>ELEYIN</b> - The mother of YEKUGBO who in turn begat the
                mother of JOEL at Ayagele;
              </li>
              <li>
                <b>TUYOLE</b> - The mother of AYE who in turn begat IYALODE (the
                mother of Prof. Sola Aliu);
              </li>
              <li>
                <b>AJIMBA</b> - Who begat AGBELEJU (the mother of IBITEYE and
                FOLAWEWO);
              </li>
              <li>
                <b>FATUMO</b> - Who died childless;
              </li>
              <li>
                <b>OKELE</b> - Who died childless;
              </li>
              <li>
                <b>ARIYUNKE</b> (Aayunke) – Who died childless;
              </li>
              <li>
                <b>OMODELE</b> (YEMOMO) – Mother of OMOLAYE who in turn begat
                ONI and JOLADE. Oni was the mother of MONISOLA and DUNJOYE
                OMISORE. Jolade married the then Olukare of Ikare.
              </li>
            </ol>
          </p>
        </div>
        <div className="aboutUsTextContain">
          <h3>The lineage of Alaarokun: Rawa of Afa-Okeagbe</h3>
          <p>
            Over the century, in Afa-Okeagbe, a rich tapestry of history and
            tradition has been woven. At the heart of this heritage lies the
            venerable lineage of Alaarokun, the first Rawa in the history of Afa
            kingdom. This esteemed dynasty has produced a succession of
            visionary leaders, each contributing to the growth, prosperity, and
            cultural heritage of Afa-Okeagbe.
          </p>
          <p>
            After Alaarokun’s reign as the first Rawa in the history of
            Afa-Okeagbe, eight (8) heirs of Alaarokun have succeeded him. The
            succession to the Rawa Stool is as follows:
            <ol className="Order">
              <li>Rawa Olete – Second Rawa of Afa-Okeagbe</li>
              <li>Rawa Eruawo – Third Rawa of Afa-Okeagbe</li>
              <li>Rawa Osala – Fourth Rawa of Afa-Okeagbe</li>
              <li>Rawa Abayomi – Fifth Rawa of Afa-Okeagbe</li>
              <li>Rawa Aliu – Sixth Rawa of Afa-Okeagbe</li>
              <li>Rawa Ogundana – Seventh Rawa of Afa-Okeagbe</li>
              <li>Rawa Johnson Ojo Aliu - Eight Rawa of Afa-Okeagbe</li>
              <li>
                Rawa Dr. Foluso Arewa – Ninth and current Rawa of Afa-Okeagbe
              </li>
            </ol>
            The above narrative provides a glimpse into the remarkable heritage
            of Alaarokun’s descendants whose leadership is characterized by
            wisdom, bravery, and an unshakeable commitment to the prosperity of
            Afa-Okeagbe.
          </p>
        </div>
      </div>
      <div style={{ paddingBottom: "2rem" }} className="fotter2">
                    <Link className="fLink" to="/">
                      HOME
                    </Link>
        <Link className="fLink" to="/about">
          ABOUT
        </Link>
        <Link className="fLink" to="/publications">
          Publications
        </Link>
        <Link className="fLink" to="/contact">
          Contact
        </Link>
        <Link className="fLink" to="/store">
          Store
        </Link>
        <Link className="fLink" to="/privacy">
          Privacy
        </Link>
        <Link className="fLink" to="/signIn">
          Administrator
        </Link>
        <p>Copyright 2024</p>
        <p>
          Made With Love By{" "}
          <Link className="special" to="#">
            Excellence
          </Link>
        </p>
      </div>
    </div>
  );
}

export default About;
