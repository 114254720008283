import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="logContain">
      <h1>ALAAROKUN DYNASTY DATA PRIVACY POLICY</h1>
      <div className="aboutUsTextContain">
        <p>
          <ol className="Order">
            <li>
              <h2>Purpose</h2> This policy outlines Alaarokun Dynasty’s
              practices regarding the collection, use, storage, and protection
              of personal data. The goal is to ensure transparency and
              compliance with applicable data protection laws, such as the
              General Data Protection Regulation (GDPR).
            </li>
            <li>
              <h2>Scope</h2> This policy applies to all personal data processed
              by Alaarokun Dynasty related to customers, website visitors,
              employees, and any other individuals with whom the organization
              interacts.
            </li>
            <li>
              <h2>Types of Data Collected</h2>{" "}
              <p>
                • Personal Information: Such as name, email address, phone
                number, and mailing address.
              </p>{" "}
              <p>
                • Financial Information: Such as payment details, credit card
                numbers, and billing addresses.{" "}
              </p>{" "}
              <p>
                • Technical Information: Such as IP addresses, browser type,
                operating system, and browsing activity on our website.
              </p>{" "}
              • Other Information: Depending on services offered, other relevant
              data may be collected as needed.
            </li>
            <li>
              <h2>Methods of Data Collection</h2> • Direct Collection:
              Information provided directly by individuals through forms,
              surveys, or communication channels. <br></br> • Automatic
              Collection: Data collected through website usage, cookies, and
              similar tracking technologies. <br />• Third-Party Sources:
              Information obtained from trusted third-party providers and public
              sources.
            </li>
            <li>
              <h2>Purpose of Data Collection</h2> • To provide and improve our
              products or services. <br /> • To process payments and fulfill
              orders. <br />• To communicate with users regarding their
              accounts, orders, or inquiries. <br />• For marketing,
              promotional, and analytical purposes, with consent. <br />• To
              comply with legal obligations and protect the organization's legal
              interests.
            </li>
            <li>
              <h2>Legal Basis for Processing</h2> • Consent: When required, we
              will obtain explicit consent for processing specific types of
              personal data. <br /> • Contractual Necessity: Data processing
              required for the performance of a contract with the individual.{" "}
              <br />• Legitimate Interests: Data processing based on our
              legitimate interests, provided they do not override individual
              rights. <br />• Legal Obligation: Processing required to comply
              with legal and regulatory requirements.
            </li>
            <li>
              <h2>Data Storage and Security</h2> Personal data is stored
              securely using industry-standard protection measures. Access is
              restricted to authorized personnel, and data is encrypted as
              necessary. We regularly review security practices to safeguard
              data from unauthorized access, loss, or disclosure.
            </li>
            <li>
              <h2>Data Retention</h2> We retain personal data only for as long
              as necessary to fulfill the purposes for which it was collected,
              or as required by law. Upon reaching the retention limit, data is
              securely deleted or anonymized.
            </li>
            <li>
              <h2>Data Sharing and Disclosure</h2> Personal data may be shared
              with: <br />• Service Providers: Trusted third parties who assist
              with services, such as payment processing, analytics, or
              marketing. <br /> • Legal Compliance: Government authorities,
              where legally required. <br />• Business Transfers: In the event
              of a merger, acquisition, or asset sale, personal data may be
              transferred.
            </li>
            <li>
              <h2>Individual Rights</h2> Under data protection laws, individuals
              have the right to: <br /> • Access: Request access to their
              personal data. <br /> • Rectification: Request correction of
              inaccurate data. <br />• Erasure: Request deletion of their data
              (right to be forgotten). <br /> • Restrict Processing: Request
              limited use of their data. <br /> • Data Portability: Receive a
              copy of their data in a structured format. <br />• Withdraw
              Consent: Withdraw previously given consent at any time.
            </li>
            <li>
              <h2>Cookies and Tracking Technologies</h2> We use cookies and
              similar technologies on our website to enhance user experience,
              analyze usage, and improve services. Individuals can manage their
              cookie preferences through their browser settings or on our
              website’s cookie settings page.
            </li>
            <li>
              <h2>Policy Updates</h2> This policy may be updated periodically to
              reflect changes in our practices or legal requirements. We will
              notify users of any significant changes by updating the policy on
              our website and, where required, obtaining renewed consent.
            </li>
            <li>
              <h2>Contact Information</h2> For questions or concerns about this
              policy or to exercise individual rights, please contact us at:{" "}
              <br /> • Email: …………………………… <br />• Address: ……………………….. <br />•
              Phone: ………………………….
            </li>
          </ol>
        </p>
      </div>
      <div style={{ paddingBottom: "2rem" }} className="fotter2">
                    <Link className="fLink" to="/">
                      HOME
                    </Link>
        <Link className="fLink" to="/about">
          ABOUT
        </Link>
        <Link className="fLink" to="/publications">
          Publications
        </Link>
        <Link className="fLink" to="/contact">
          Contact
        </Link>
        <Link className="fLink" to="/store">
          Store
        </Link>
        <Link className="fLink" to="/privacy">
          Privacy
        </Link>
        <Link className="fLink" to="/signIn">
          Administrator
        </Link>
        <p>Copyright 2024</p>
        <p>
          Made With Love By{" "}
          <Link className="special" to="#">
            Excellence
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
