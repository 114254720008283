import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import "../css/publications.css";
import ImagesVar from "../data/ImageVar";
import { Link } from "react-router-dom";

function Publications() {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      const resourcesCollection = collection(db, "resources");
      const resourcesSnapshot = await getDocs(resourcesCollection);
      const resourcesList = resourcesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResources(resourcesList);
    };

    fetchResources();
  }, []);

  return (
    <div>
      <div className="bodPublic">
        <h1>publications</h1>
        <p>A page to post ALAAROKUN publications, Documents, PDFs</p>
        <div className="subTitle">
          <p className="date">
            <span style={{ color: "#fdb022" }}>#</span>Resources
          </p>
          <div className="likeContainP">
            <i className="fa-regular fa-file"></i>
            <p className="date1">Documents </p>
          </div>
        </div>
        <div className="logContain">
          <div className="documentContain">
            {resources.map((resource) => (
              <div className="document">
                <div key={resource.id} className="topP">
                  <div className="nameNpic">
                    <img alt="profile" src={ImagesVar.avatar} />
                    <p>Arewa Folusho</p>
                  </div>
                  <p className="date">
                    {resource.createdAt
                      ? new Date(
                          resource.createdAt.seconds * 1000
                        ).toDateString()
                      : "Date not available"}
                  </p>
                </div>
                <div className="bottomP">
                  <div className="bottomCard">
                    <h2>{resource.title}</h2>
                    <a
                      href={resource.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button">Download</button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="fotter2">
                        <Link className="fLink" to="/">
                          HOME
                        </Link>
            <Link className="fLink" to="/about">
              ABOUT
            </Link>
            <Link className="fLink" to="/publications">
              Publications
            </Link>
            <Link className="fLink" to="/contact">
              Contact
            </Link>
            <Link className="fLink" to="/store">
              Store
            </Link>
            <Link className="fLink" to="/privacy">
              Privacy
            </Link>
            <Link className="fLink" to="/signIn">
              Administrator
            </Link>
            <p>Copyright 2024</p>
            <p>
              Made With Love By{" "}
              <Link className="special" to="#">
                Excellence
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Publications;
