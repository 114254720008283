import "./assets/css/globalStyles.css";
import "./assets/css/index.css";
import ImagesVar from "./assets/data/ImageVar";
import { useEffect, useState } from "react";
import { db } from "./firebase";
import React from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import DOMPurify from "dompurify";

function Home() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("latest"); // New state for category

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const postsCollection = collection(db, "posts");
        const postsSnapshot = await getDocs(postsCollection);
        const postsList = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsList);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const handleLike = async (postId, currentLikes) => {
    const newLikes = currentLikes + 1;
    const postRef = doc(db, "posts", postId);

    try {
      await updateDoc(postRef, { likes: newLikes });
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, likes: newLikes } : post
        )
      );
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  

  // Sort posts by createdAt for latest category, and filter based on selected category
  const sortedAndFilteredPosts = [...posts]
    .sort((a, b) => {
      if (selectedCategory === "latest" && a.createdAt && b.createdAt) {
        return b.createdAt.seconds - a.createdAt.seconds;
      }
      return 0;
    })
    .filter((post) => {
      if (selectedCategory === "latest") return true;
      if (selectedCategory === "top") return post.likes && post.likes > 0;
      if (selectedCategory === "exclusive") return post.isPremium === true;
      return true;
    });

  return (
    <div className="App">
      <div className="blogContain">
      <div className="blog">
          <div className="category">
            <button
              className={`categoryText ${selectedCategory === "latest" ? "active" : ""}`}
              onClick={() => setSelectedCategory("latest")}
            >
              <i className="fa-solid fa-square-poll-vertical"></i> Latest Posts
            </button>
            <button
              className={`categoryText ${selectedCategory === "top" ? "active" : ""}`}
              onClick={() => setSelectedCategory("top")}
            >
              <i className="fa-solid fa-award"></i> Top Posts
            </button>
            <button
              className={`categoryText ${selectedCategory === "exclusive" ? "active" : ""}`}
              onClick={() => setSelectedCategory("exclusive")}
            >
              <i className="fa-solid fa-star"></i> Exclusive Content
            </button>
          </div>

          <div className="mainBlog">
            {loading ? (
              <h1>Loading blogs...</h1>
            ) : sortedAndFilteredPosts.length > 0 ? (
              sortedAndFilteredPosts.map((post) => (
                <div key={post.id} className="postContain">
                  <div className="postLeft">
                    <div className="nameNpic">
                      <img
                        alt="profile"
                        src={ImagesVar.avatar || "/default-avatar.png"}
                      />
                      <p>Alaarokun</p>
                    </div>

                    <Link to={`/blog/${post.id}`} className="postMain">
                      <h2>{post.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            post.content?.length > 20
                              ? `${post.content.substring(0, 50)}...`
                              : post.content
                          ),
                        }}
                      />
                    </Link>

                    <div className="details">
                      <div className="dateContain">
                        <p className="date1">
                          {post.createdAt
                            ? new Date(
                                post.createdAt.seconds * 1000
                              ).toDateString()
                            : "Date not available"}
                        </p>
                      </div>
                      <div
                        onClick={() => handleLike(post.id, post.likes || 0)}
                        className="likeContainh"
                      >
                        <i className="fa-regular fa-heart"></i>
                        <p className="date1">Likes {post.likes || 0}</p>
                      </div>
                      <div className="likeContainh">
                        <i className="fa-regular fa-comment"></i>
                        <p className="date1">
                           {post.comments?.length || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="postRight">
                    <img
                      alt="post"
                      src={post.coverImage || "/default-post.png"}
                    />
                  </div>
                </div>
              ))
            ) : (
              <h1>No blogs available</h1>
            )}
          </div>
        </div>

        <div className="side">
          <p className="featuredHead">FEATURED ARTICLES</p>
          <div className="featured">
            {posts.slice(0, 2).map((post) => (
              <Link
                to={`/blog/${post.id}`}
                key={post.id}
                className="featuredPost"
              >
                <div className="featuredNameNpic">
                  <img
                    alt="profile"
                    src={ImagesVar.avatar || "/default-profile.png"}
                  />
                  <p>Alaarokun</p>
                </div>
                <div className="featuredPostMain">
                  <h3>{post.title}</h3>
                  <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            post.content?.length > 10
                              ? `${post.content.substring(0, 40)}...`
                              : post.content
                          ),
                        }}
                      />
                </div>
              </Link>
            ))}
            <div className="ads"></div>
            <div className="footer">
              <Link className="fLink" to="/">
                HOME
              </Link>
              <Link className="fLink" to="/about">
                ABOUT
              </Link>
              <Link className="fLink" to="/publications">
                Publications
              </Link>
              <Link className="fLink" to="/contact">
                Contact
              </Link>
              <Link className="fLink" to="/store">
                Store
              </Link>
              <Link className="fLink" to="/privacy">
                Privacy
              </Link>
              <Link className="fLink" to="/signIn">
                Administrator
              </Link>
              <p>Copyright 2024</p>
              <p>
                Made With Love By{" "}
                <Link className="special" to="#">
                  Excellence
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
